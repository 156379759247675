import { get, isString, includes } from 'lodash-es';
import axios from 'axios';
import { VUE_APP_THUMBNAILS_CLOUDFRONT_DISTRIBUTION_URL } from '@/lib/constants/app';
import { moduleTypeLabels, APP_COPY_DATA_TYPE } from '@/lib/constants/index';
import i18next from '@/lib/i18next';
import defaultThumbnailUrl from '@/assets/blank_board.png';

/**
 * Decode HTML entities. Since <textarea> cannot contain html, there is no risk of XSS.
 * https://stackoverflow.com/a/1395954/8373513
 * @param {string} html - The html to decode
 */
export function decodeHTML(html) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = html || '';
  return textarea.value;
}

/**
 * clean up encoding for an orion text module
 */
export function cleanOrionTextModule(mod) {
  const updatedModule = mod;
  if (updatedModule.type === 'text' && updatedModule.options.data_type === 'orion') {
    updatedModule.options.content = decodeHTML(updatedModule.options.content);
  }

  return updatedModule;
}

/** Only encodes <, >, & */
export function encodeHTML(html) {
  if (!html || typeof html !== 'string') return '';

  return html.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
}

/**
 * Get duration string 00:00:00 for seconds up to 99 hours
 * @param s - seconds
 */
export function secondsToDuration(s) {
  if (!s) return '00:00:00';

  const hours = Math.floor(s / 3600);
  const minutes = Math.floor((s - (hours * 3600)) / 60);
  let seconds = s - (hours * 3600) - (minutes * 60);

  seconds = Math.round(Math.round(seconds * 100) / 100);

  let result = '';

  if (hours > 1) {
    result += `${hours < 10 ? '0' : ''}${hours}:`;
  }

  result += `${minutes < 10 ? '0' : ''}${minutes}:`;
  result += `${seconds < 10 ? '0' : ''}${seconds}`;
  return result;
}

export function snakeToPascal(str) {
  if (!str) return '';

  const words = str.split('_');

  return words
    .map((w) => `${w[0].toUpperCase()}${w.substr(1)}`)
    .join('');
}

/**
 * Strip tags from an html input. This is adapted from the nunjucks "striptags" filter:
 * https://github.com/mozilla/nunjucks/blob/master/nunjucks/src/filters.js
 * @param {string} html - The html to strip
 */
export function stripTags(html) {
  let str = html;
  if (html === null || html === undefined || html === false) {
    str = '';
  }

  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>|<!--[\s\S]*?-->/gi;

  return str.replace(tags, '') // strip tags
    .replace(/^\s*|\s*$/g, '') // strip leading/trailing space
    .replace(/\s+/gi, ' '); // squash adjacent spaces
}

export function moduleTypeToComponent(type) {
  return `${snakeToPascal(type)}Module`;
}

export function moduleTypeToOptions(type) {
  return `${snakeToPascal(type)}ModuleOptions`;
}

export function moduleTypeToLabel(type) {
  if (!type) return '';

  if (moduleTypeLabels()[type]) return moduleTypeLabels()[type];

  const words = type.split('_');

  return words
    .map((w) => `${w[0].toUpperCase()}${w.substr(1)}`)
    .join(' ');
}

export function parseDateString(dateString) {
  if (!isString(dateString)) return null;
  return new Date(dateString.slice(-1) === 'Z' ? dateString : `${dateString}Z`);
}

/**
 * Format a date into a human-readable format
 * @param {string|date} date - The date as an ISO string or instance of Date
 * @param {boolean} includeTime - Whether to include formatted time in the output
 */
export function formatDate(date, includeTime, includeDate = true) {
  if (!date) return '';
  let parsedDate = date;
  const formattedDate = [];

  try {
    if (typeof date === 'string') {
      // Mongo dates seem to leave the final "Z" off UTC dates, which is
      // necessary for javascript to account for timezone in its output
      parsedDate = parseDateString(date);
    }
    const locale = i18next.language || 'en';

    if (includeDate) {
      formattedDate.push(parsedDate.toLocaleDateString(locale));
    }

    if (includeTime) {
      formattedDate.push(`${parsedDate.toLocaleTimeString(locale, {
        hour: 'numeric',
        minute: '2-digit',
      })}`);
    }
    return formattedDate.join(' ');
  } catch (e) {
    throw new Error(e);
  }
}

export function formatPageTitle(page, idx, draftType) {
  // If we've got a title already, that's great; return it with decoded HTML entities
  if (page && page.title) return decodeHTML(page.title);
  // Otherwise make a default page title like "Slide 4"
  return draftType === 'board'
    ? i18next.t('Page %(idx)s', { idx: idx + 1 })
    : i18next.t('Slide %(idx)s', { idx: idx + 1 });
}

export function toInitial(str) {
  if (!str) return '';

  return str.charAt(0);
}

/**
 * Check a string to see if it is a valid guid.
 * @param {string} str - The string to test
 * @returns {boolean} Whether the stripped string is a guid
 */
export function isGuid(str) {
  const guidRegex = /^[a-f\d]{8}-(?:[a-f\d]{4}-){3}[a-f\d]{12}$/i;
  return guidRegex.test(str.trim());
}

const inputTypes = ['text', 'password', 'number', 'email', 'tel', 'url', 'search',
  'date', 'datetime', 'datetime-local', 'time', 'month', 'week'];

export function isTextInput(element) {
  const tagName = element.tagName.toLowerCase();
  if (tagName === 'textarea') return true;
  if (tagName === 'input') {
    const type = get(element, 'type', '').toLowerCase();
    if (inputTypes.includes(type)) return true;
  }
  if (element.className.includes('ProseMirror')) return true;
  return false;
}

export function isMobileOrTablet() {
  const mobileOrTablet = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  const iPadPro = navigator.maxTouchPoints && navigator.maxTouchPoints > 1 && /Macintosh/i.test(navigator.userAgent);
  return mobileOrTablet || iPadPro;
}

export async function getPageThumbnail(page, defaultThumbnail) {
  const thumbnailCloudfrontUrl = VUE_APP_THUMBNAILS_CLOUDFRONT_DISTRIBUTION_URL;
  let thumbnail = {};
  return axios.head(`${thumbnailCloudfrontUrl + page?.id}-small`,
    {
      withCredentials: false,
    })
    .then((response) => {
      thumbnail = { url: response.config.url, isStudioThumbnail: false };
      return thumbnail;
    })
    .catch(() => {
      thumbnail = { url: get(page, 'thumbnail.url', defaultThumbnail), isStudioThumbnail: true };
      return thumbnail;
    });
}

export async function getRefThumbnail(draft, page) {
  const revisionId = draft.references.find(
    (ref) => ref.reference_type === 'asset' && ref.reference_subtype === 'revision',
  ).reference_id;

  const headId = draft.references.find(
    (ref) => ref.reference_type === 'asset' && ref.reference_subtype === 'head',
  ).reference_id;

  const thumbnailCloudfrontUrl = VUE_APP_THUMBNAILS_CLOUDFRONT_DISTRIBUTION_URL;
  const revisionThumbUrl = `${thumbnailCloudfrontUrl}${revisionId}-large`;
  const headThumbUrl = `${thumbnailCloudfrontUrl}${headId}-large`;
  const pageThumbUrl = `${thumbnailCloudfrontUrl}${page?.id}-large`;

  return new Promise((resolve, reject) => {
    const options = {
      withCredentials: false,
    };

    // if the revision thumb exists, use that
    axios.head(revisionThumbUrl, options)
      .then((response) => {
        const thumbnail = { url: response.config.url };
        resolve(thumbnail);
      })
      .catch(() => { // eslint-disable-line
        // otherwise use the head thumb
        axios.head(headThumbUrl, options)
          .then((response) => {
            const thumbnail = { url: response.config.url };
            resolve(thumbnail);
          })
          .catch(() => { // eslint-disable-line
            // no head thumb? use the page thumb
            const fallbackThumb = { url: get(page, 'thumbnail.url', defaultThumbnailUrl) };
            if (!page?.id && fallbackThumb) {
              resolve(fallbackThumb);
            } else {
              axios.head(pageThumbUrl, options)
                .then((response) => {
                  const thumbnail = { url: response.config.url };
                  resolve(thumbnail);
                })
                .catch((e) => {
                  reject(e);
                });
            }
          });
      });
  });
}

export function canUseWebAnimationsApi() {
  return !!document.createElement('div').animate;
}

export function getSegmentObjFromExperimentData(experimentObj) {
  // Remove nested object
  // Move experiment_id and variations into in the same obj for segment to use the data
  const segmentFormat = {
    experiment_id: experimentObj?.code,
    variation_id: experimentObj.variation?.variation_id,
    variation_code: experimentObj.variation?.variation_code,
    // Change value to a decimal percent for segment
    variation_percentage: (experimentObj.variation?.variation_percentage) / 100,
    variation_is_control: experimentObj.variation?.variation_is_control,
  };

  return segmentFormat;
}

export function durationToSeconds(video) {
  if (includes(get(this, video), ':')) {
    return get(this, video).split(':')
      .reduce((acc, time) => (60 * acc) + +time);
  }

  if (get(this, video)) {
    return Number(get(this, video));
  }
  return null;
}

/**
 * Finds the last descendant node that has content in document order.
 * @param {Node} sourceNode Node to search.
 */
export function lastNodeWithContent(sourceNode) {
  let last;
  const tagName = (node) => node.tagName?.toLowerCase();
  /*
    Elements considered to have content regardless of what's inside.
  */
  const elements = new Set(['table']);
  const findLastTextNode = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.trim().length > 0) {
        last = node;
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      if (elements.has(tagName(node))) {
        last = node;
      } else if (node.hasChildNodes()) {
        node.childNodes.forEach((child) => findLastTextNode(child));
      }
    }
  };
  findLastTextNode(sourceNode);
  return last;
}

/**
 * Removes trailing descendant nodes that do not have content.
 * @param {Node} source Node to trim child nodes from.
 */
export function trimHtml(source) {
  const target = document.createElement('div');
  const lastNode = lastNodeWithContent(source);
  const trim = (sourceNode, targetNode) => {
    if (sourceNode.hasChildNodes()) {
      for (let index = 0; index < sourceNode.childNodes.length; index += 1) {
        const sourceChild = sourceNode.childNodes[index];
        if (sourceChild.nodeType === Node.TEXT_NODE) {
          targetNode.appendChild(document.createTextNode(sourceChild.textContent));
        } else if (sourceChild.nodeType === Node.ELEMENT_NODE) {
          const targetChild = document.createElement(sourceChild.tagName);
          targetChild.className = sourceChild.className;
          targetNode.appendChild(targetChild);
          if (!trim(sourceChild, targetChild)) {
            return false;
          }
        }
        /*
          If we've found the last node with content then exit. All nodes after
          this node will not be included.
        */
        if (sourceChild === lastNode) {
          return false;
        }
      }
    }
    return true;
  };
  trim(source, target);
  return target.innerHTML;
}

/**
 * Extract supported elements from html content. Preserve document structure
 * while exlcuding elements that are not supported and removing node attributes.
 * @param {String} html Html string to extract from.
 * @param {Set} elements Element names that should be extracted.
 * @param {Boolean} trim If true, will remove trailing descendant nodes without content.
 * @param {Set} allowedClasses HTML class names that should be extracted
 */
export function extractHtml(html, elements = new Set(['br', 'div', 'span']), trim = true, allowedClasses = null) {
  const source = document.createElement('div');
  const target = document.createElement('div');
  source.innerHTML = html || '';
  const tagName = (node) => node.tagName?.toLowerCase();
  /*
    Clones the content from the sourceNode to the targetNode.
    Removes all node attributes and unsupported elements.
  */
  const extract = (sourceNode, targetNode) => {
    const sourceTag = tagName(source);
    if (sourceNode.hasChildNodes() && elements.has(sourceTag)) {
      sourceNode.childNodes.forEach((sourceChild) => {
        if (sourceChild.nodeType === Node.TEXT_NODE) {
          const targetChild = document.createTextNode(sourceChild.textContent);
          targetNode.appendChild(targetChild);
        } else if (sourceChild.nodeType === Node.ELEMENT_NODE) {
          const childTag = tagName(sourceChild);
          if (elements.has(childTag)) {
            const targetChild = document.createElement(childTag);
            extract(sourceChild, targetChild);

            // Extract any allowed classes
            if (allowedClasses) {
              sourceChild.classList.forEach((cls) => {
                if (allowedClasses.has(cls)) {
                  targetChild.classList.add(cls);
                }
              });
            }

            targetNode.appendChild(targetChild);
          }
        }
      });
    }
  };
  extract(source, target);

  if (trim) {
    return trimHtml(target);
  }

  return target.innerHTML;
}

/**
 * Extract text content from html content.
 */
export function extractTextFromHtml(html) {
  const source = document.createElement('div');
  source.innerHTML = html || '';
  return source.textContent;
}

/*
Check if studio is an iframe
*/
export function isIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function getBrowserZoomValue() {
  if (window) {
    return Math.round((window.outerWidth / window.innerWidth) * 100);
  }
  return false;
}

export function handleTextInputPaste(e) {
  try {
    // Parse the data as JSON
    const parsedData = JSON.parse(e.clipboardData.getData('text'));
    if (parsedData.type === APP_COPY_DATA_TYPE) {
      e.preventDefault();
    }
  } catch (error) {
    // If parsing fails, it means the data is not JSON, so we can ignore
  }
}
